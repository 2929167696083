<template>
  <div class="main-content row q-pa-md">
    <Mural
      :notificationQuantity="4"
      :breadcrumbs="breadcrumbs"
      route="training"
      :query="{ id: courseId }"
    >
      <DefaultBtn
        titleButton="Conteúdo"
        v-show="btnAddTraining"
        :query="{
          courseId: courseId,
          trainingId: trainingId,
          postId: postId,
          tutorId: tutor_id,
        }"
        route="new-content"
      />
    </Mural>
    <div class="training-content row q-mt-md col-12">
      <div style="" class="media col-sm-12 col-md-7 q-pa-md">
        <div
          class="row q-mb-sm"
          :key="media.id"
          :id="media.id"
          v-for="(media, index) in medias"
        >
          <div class="cards-media">
            <q-checkbox
              left-label
              v-model="media.concluded"
              color="green"
              disable
              checked-icon="task_alt"
              unchecked-icon="o_check_circle"
            />

            <MediaCard
              v-if="media.type == 'mp4'"
              :title="media.title"
              :image="url_aws_bucket + media.name"
              :date_created="media.created_at"
              @publish="() => checkConcluded(media.id, index)"
            />
            <CardQuestion
              v-else-if="media.type == 'quiz'"
              :courseId="courseId"
              :trainingId="trainingId"
              :quizId="media.quizid"
              :quiz="media"
            />

            <KnowledgeCard
              v-else-if="media.type == 'game'"
              :evalutation="media"
              :courseId="courseId"
              :trainingId="trainingId"
              :postId="postId"
              :quizId="media.id"
              route="game"
              :query="{
                courseId: courseId,
                trainingId: trainingId,
                gameId: media.id,
                postId: postId,
              }"
              :date="media.created_at"
              typeBadge="Conquistas"
              iconBadge="Interactivity"
            />
            <KnowledgeCard
              v-else-if="media.type == 'evaluation'"
              :evalutation="media"
              :courseId="courseId"
              :trainingId="trainingId"
              :postId="postId"
              :quizId="media.quizid"
              :isConcluded="media.concluded"
              route="exam"
              :query="{
                courseId: courseId,
                trainingId: trainingId,
                quizId: media.quizid,
                postId: postId,
                concluded: media.concluded,
                openQuestion: media.open_question,
              }"
              :date="media.created_at"
              typeBadge="Quiz"
              iconBadge="book"
            />
            <KnowledgeCard
              v-else-if="['png', 'jpg'].includes(media.type)"
              :date="media.created_at"
              :title="media.title"
              :image="url_aws_bucket + media.name"
              :isConcluded="media.concluded"
              @concluded="() => checkConcluded(media.id, index)"
            />
            <KnowledgeCard
              v-else
              :evalutation="media"
              :date="media.created_at"
              typeBadge="file"
              :link="url_aws_bucket + media.name"
              :iconBadge="media.type == 'pdf' ? 'pdf' : 'doc'"
              @publish="() => checkConcluded(media.id, indece)"
            />
          </div>
        </div>
        <div>
          <div v-for="(live, index) in liveClass">
            <KnowledgeCardForLive
              :title="live.title"
              :live="live"
              :id="live.id"
              :date="live.created_at"
              typeBadge="add"
              iconBadge="video_camera_front"
              :key="index"
              :historyRouter="{
                courseId: courseId,
                trainingId: trainingId,
                postId: postId,
                tutorId: tutor_id,
              }"
            />
          </div>
        </div>
      </div>
      <div class="flex col-sm-12 col-md-5 q-pa-md coments">
        <SectionComents :coments="comments" :postId="postId" awnser="" />
      </div>
    </div>
  </div>
</template>

<script>
import Mural from "@/components/Mural.vue";
import MediaCard from "@/components/courses/cards/MediaCard";
import DefaultBtn from "@/components/shared/buttons/DefaultBtn.vue";
import DownloadFileCard from "@/components/courses/cards/DownloadFileCard";
import SectionComents from "@/components/Feed/post/comments/SectionComents.vue";
import KnowledgeCard from "@/components/courses/cards/KnowledgeCard";
import KnowledgeCardForLive from "@/components/courses/cards/KnowledgeCardForLive";
import CardScorm from "@/components/courses/cards/CardScorm.vue";
import CardQuestion from "@/components/courses/cards/CardQuestion.vue";

import TrailDataServices from "@/services/TrailDataServices";
import PostDataServices from "@/services/PostDataServices";
import BreadcrumbsDataServices from "@/services/BreadcrumbsDataServices";

import CanUserAccessClass from "@/utils/CanUserAccessClass";
import { ENV } from "@/utils/env";

import { useRoute } from "vue-router";
import { ref, onMounted } from "vue";
import { useStore } from "vuex";

export default {
  name: "training-page",
  components: {
    Mural,
    DefaultBtn,
    CardQuestion,
    DownloadFileCard,
    MediaCard,
    SectionComents,
    KnowledgeCard,
    KnowledgeCardForLive,
    CardScorm,
  },
  setup() {
    /* CONSTANTS */
    const store = new useStore();
    /* VARIABLES */
    let breadcrumbs = ref([]);
    let medias = ref([]);
    let medias_count = ref(0);
    let url_aws_bucket = ENV.URL_AWS_BUCKET;
    let trailDataServices = new TrailDataServices();
    let postDataServices = new PostDataServices();
    let breadcrumbsDataServices = new BreadcrumbsDataServices();
    let route = new useRoute();
    let comments = ref([]);
    let postId = route.query.postId;
    let courseId = route.query.courseId;
    let trainingId = route.query.trainingId;
    let btnAddTraining = ref(true);
    let canUserAccessClass = new CanUserAccessClass();
    let tutor_id = route.query.tutorId;
    const liveClass = ref([]);

    onMounted(() => {
      getAllContentMedia(route.query.courseId, route.query.trainingId);
      getAllLiveClass();
      GetCommentsOfTrainging(route.query.postId);
      GetBreadcrumbsTrainingPage(route.query.courseId, route.query.trainingId);
      ValidateActionsPage();
    });

    /* FUNCTIONS */
    async function ValidateActionsPage() {
      if (!canUserAccessClass.canUserAccess("new-training", false)) {
        btnAddTraining.value = false;
        return;
      }
    }

    async function getAllLiveClass() {
      await trailDataServices
        .getAllLiveClass(trainingId)
        .then((response) => {
          response.data.forEach((r) => {
            if (!r.delete_at) {
              liveClass.value.push(r);
            }
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }

    async function getAllContentMedia(courseId, trainingId) {
      let data = await trailDataServices.getAllContentStudent(
        courseId,
        trainingId
      );

      data.data.forEach((content) => {
        if (
          content?.status == 1 ||
          content?.state ||
          ["quiz", "evaluation"].includes(content.type)
        ) {
          medias.value.push(content);
        }
      });

      medias_count.value = medias.value.length;
    }

    async function checkConcluded(mediaId, indece) {
      await trailDataServices.checkConcludeMedia(
        route.query.courseId,
        route.query.trainingId,
        mediaId
      );
      medias.value[indece].concluded = true;
    }

    async function GetCommentsOfTrainging(postId) {
      let data = await postDataServices.getCommentsByPost(postId, 1, 3);

      data.data.forEach((element) => {
        comments.value.push(element);
      });
    }

    async function GetBreadcrumbsTrainingPage(courseId, trainingId) {
      let result = await breadcrumbsDataServices.GetBreadcrumbsTrainingPage(
        courseId,
        trainingId
      );
      breadcrumbs.value = result.data.split("/");
    }

    return {
      breadcrumbs,
      courseId,
      trainingId,
      postId,
      btnAddTraining,
      url_aws_bucket,
      medias,
      comments,
      checkConcluded,
      liveClass,
    };
  },
};
</script>

<style lang="scss" scoped>
.main-content {
  width: 100%;
  height: 100%;

  .training-content {
    width: 100%;
    height: 75vh;
    overflow-y: auto;
  }
}

.media,
.coments {
  height: 70vh;
  overflow-y: auto;
}

.cards-media {
  width: 100%;
  display: flex;
}
</style>
