<template>
  <div
    class="flex column justify-center items-center notification-card"
  >
    <div class="flex row items-center justify-between main">
     
      <div class="badge-icon q-mr-sm">
        <q-icon name="o_sports_esports" color="secondary" size="2em"/>
      </div>
      <div class="title-notification">
        <h6>{{ evalutation.title }}</h6>
        <small>Adicionado em {{ dateFormart }}</small>
      </div>
      <div class="flex link-notification">
         <RoundedBtn  titleButton="Iniciar" isDense />
      </div>
    </div>
  </div>
</template>

<script>
import RoundedBtn from "@/components/shared/buttons/RoundedBtn.vue";
import BadgeType from "@/components/BadgeType.vue";
import ColorsClass from "@/utils/styles/colors/getColors.js";
import { date } from "quasar";

export default {
  name: "KnowledgeCard",
  components: {
    RoundedBtn,
    BadgeType,
  },
  props: {
    evalutation: Object,
    courseId: String,
    trainingId: String,
    quizId: Number,
    postId: String,
    title: String,
    date: String,
  },
  setup(props) {
    //-----CONSTANTE
    const Colors = new ColorsClass();

    let dateFormart = date.formatDate(props.date, "DD/MM/YYYY H:mm");
    function getColorBgBadge(name) {
      return  Colors.getColorBgBadge(name);
    }

    return {
      getColorBgBadge,
      dateFormart,
    };
  },
};
</script>

<style lang="scss" scoped>
.notification-card {
  width: 100%;
  /* height: 100%;
  max-height: 162px; */
  margin-bottom: 1rem;
  padding: 1% 2%;
  background: #fdfdfd;
  border-radius: 20px;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.039),
    0px 0.5px 1.5px rgba(0, 0, 0, 0.19);
  box-shadow: rgb(0 0 0 / 10%) 3px 6px 15px;
}

.banner {
  width: 95%;
  height: 188px;
  overflow: hidden;
  margin-bottom: 2%;
  border-radius: 20px;
}

.main {
  width: 100%;
  position: relative;

  &::before {
    content: "";
    width: 3px;
    height: 35px;
    /* background-color: #a1cb72; */
    position: absolute;
    left: -2%;
    border-radius: 0px 2px 2px 0px;
  }
  .badge-icon {
    width: 45px;
    height: 45px;
  }

  .title-notification {
    flex: 1;
    h6 {
      margin: 0;
      font-style: normal;
      font-weight: 700;
      font-size: 1rem;
      line-height: 17px;
    }
    span {
      margin: 0;
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      line-height: 17px;
    }

    small {
      font-style: normal;
      font-weight: 500;
      font-size: 0.62rem;
      line-height: 12px;
      color: #0000004d;
    }
  }
  /* .link-notification{
    flex: 1;
  } */
}

.item-bg-pink {
  .main::before {
    background-color: var(--q-primary, #fe4e64);
  }
}
.item-bg-yellow {
  .main::before {
    background-color: #d49a41;
  }
}
.item-bg-purple {
  .main::before {
    background-color: #a55ab3;
  }
}
.item-bg-blue {
  .main::before {
    background-color: #3f8ab3;
  }
}
.item-bg-green {
  .main::before {
    background-color: #77aa3d;
  }
}
.item-bg-depp-green {
  .main::before {
    background-color: #439380;
  }
}
</style>
