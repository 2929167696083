<template>
  <div
    class="flex column justify-center items-center notification-card"
    :class="[getColorBgBadge(typeBadge)]"
  >
    <div class="flex banner" v-if="image != null">
      <q-img :src="`${image}`" />
      <div class="header col-12 q-pt-md">
        <div class="avatar-notification q-mx-md">
          <BadgeType typeBadge="file" iconBadge="image" />
        </div>
        <div class="title-Info">
          <h6>{{ title }}</h6>
          <small>Adiciondo em {{ dateFormart }}</small>
        </div>
      </div>
    </div>
    <div
      class="flex row items-center justify-between main"
      v-if="image == null"
    >
      <div class="badge-icon q-mr-sm">
        <BadgeType
          :typeBadge="typeBadge"
          :iconBadge="iconBadge"
          :isDoc="typeBadge == 'file'"
        />
      </div>
      <div class="title-notification">
        <h6>{{ evalutation.title }}</h6>
        <small>Adicionado em {{ dateFormart }}</small>
      </div>
      <div v-if="typeBadge != 'file'" class="row no-wrap link-notification">
        <RoundedBtn
          :titleButton="isConcluded ? 'Concluído' : 'Acessar'"
          :route="route"
          :query="query"
          isDense
        />
      </div>
      <a
        v-else
        :href="link"
        download
        target="_blank"
        @click="$emit('publish')"
        class="q-pr-md"
      >
        <Download />
      </a>

      <div class="q-ml-md">
        <q-btn
          class="q-py-sm"
          v-show="
            evalutation.show_review &&
            evalutation.open_question &&
            evalutation.concluded
          "
          no-caps
          rounded
          color="default-pink"
          icon="remove_red_eye"
          label="Visualizar correção"
          :to="{
            name: 'view-open-questions',
            query: {
              title: evalutation.title,
              quizStudent_id: evalutation.quiz_students_id,
              course_id: course_id,
              training_id: training_id,
              quiz_id: evalutation.quizid,
            },
          }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import downloadImg from "@/assets/icons/download.svg";
import Download from "@/assets/icons/custom/Download.vue";

import RoundedBtn from "@/components/shared/buttons/RoundedBtn.vue";
import BadgeType from "@/components/BadgeType.vue";
import ColorsClass from "@/utils/styles/colors/getColors.js";
import { date } from "quasar";

import { useRoute } from "vue-router";

import { onMounted } from "vue";

export default {
  name: "KnowledgeCard",
  components: {
    RoundedBtn,
    BadgeType,
    Download,
  },
  props: {
    evalutation: Object,
    courseId: String,
    trainingId: String,
    quizId: Number,
    postId: String,
    isConcluded: {
      type: Boolean,
      default: false,
    },
    route: {
      type: String,
      default: "",
    },
    query: {
      type: Object,
      default: {},
    },
    image: String,
    title: String,
    date: String,
    typeBadge: {
      type: String,
      default: "item-bg-depp-green",
    },
    iconBadge: {
      type: String,
      default: "book",
    },
    link: {
      type: String,
      default: "",
    },
  },
  emits: ["concluded"],
  setup(props, { emit }) {
    //-----CONSTANTE
    const Colors = new ColorsClass();
    const route = new useRoute();
    const course_id = route.query.courseId;
    const training_id = route.query.trainingId;

    let dateFormart = date.formatDate(props.date, "DD/MM/YYYY H:mm");
    function getColorBgBadge(name) {
      return Colors.getColorBgBadge(name);
    }

    onMounted(() => {
      if (props.image != null && !props.isConcluded) {
        emit("concluded");
      }
    });

    return {
      //----IMAGE
      downloadImg,

      getColorBgBadge,
      dateFormart,

      training_id,
      course_id,
    };
  },
};
</script>

<style lang="scss" scoped>
.notification-card {
  width: 100%;
  /* height: 100%;
  max-height: 162px; */
  margin-bottom: 1rem;
  padding: 1% 2%;
  background: #fdfdfd;
  border-radius: 20px;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.039),
    0px 0.5px 1.5px rgba(0, 0, 0, 0.19);
  box-shadow: rgb(0 0 0 / 10%) 3px 6px 15px;
}

.banner {
  width: 95%;
  height: fit-content;
  overflow: hidden;
  margin-bottom: 2%;
  border-radius: 20px;
}

.banner .q-img {
  border-radius: 20px;
}

.main {
  width: 100%;
  position: relative;

  &::before {
    content: "";
    width: 3px;
    height: 35px;
    /* background-color: #a1cb72; */
    position: absolute;
    left: -2%;
    border-radius: 0px 2px 2px 0px;
  }
  .badge-icon {
    width: 45px;
    height: 45px;
  }

  .title-notification {
    flex: 3;
    h6 {
      margin: 0;
      font-style: normal;
      font-weight: 700;
      font-size: 1rem;
      line-height: 17px;
    }
    span {
      margin: 0;
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      line-height: 17px;
    }

    small {
      font-style: normal;
      font-weight: 500;
      font-size: 0.62rem;
      line-height: 12px;
      color: #0000004d;
    }
  }
  .link-notification {
    flex: 1.5;
  }
}

.item-bg-pink {
  .main::before {
    background-color: var(--q-primary, #fe4e64);
  }
}
.item-bg-yellow {
  .main::before {
    background-color: #d49a41;
  }
}
.item-bg-purple {
  .main::before {
    background-color: #a55ab3;
  }
}
.item-bg-blue {
  .main::before {
    background-color: #3f8ab3;
  }
}
.item-bg-green {
  .main::before {
    background-color: #77aa3d;
  }
}
.item-bg-depp-green {
  .main::before {
    background-color: #439380;
  }
}

.header {
  display: flex;
  flex-direction: row;
  position: relative;

  &::before {
    content: "";
    width: 3px;
    height: 35px;
    position: absolute;
    border-radius: 0px 2px 2px 0px;
  }

  .avatar-notification {
    width: 45px;
    /*  height: 45px; */
  }

  .title-Info {
    width: 80%;

    h6 {
      margin: 0;
      font-style: normal;
      font-weight: 700;
      font-size: 1rem;
      line-height: 17px;
    }

    span {
      margin: 0;
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      line-height: 17px;
    }

    small {
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
      color: #0000004d;
    }
  }
}
</style>
